// eslint-disable-next-line import/prefer-default-export
import {Tooltip} from '../../components/core';
import {AudioIcon, SubtitlesIcon} from '../../components/core/icons';
import {TableHeaderColumnTooltip, generateTableSubColumns} from '../../helpers/components/table';

// eslint-disable-next-line import/prefer-default-export
export const tableAddPlaylistVideoColumns = () => ([
    {
        Header: () => TableHeaderColumnTooltip({
            text: 'MCBT/AID',
        }),
        hasWidthAuto: true,
        Cell: ({row}: {row: any}) => (<strong>{(row.original.mutations || [])[0]?.video.mcbt}</strong>),
        id: 'mcbt',
    },
    {
        Header: () => TableHeaderColumnTooltip({
            text: 'Theme',
        }),
        Cell: ({row}: {row: any}) => {
            const theme = (row.original.mutations || [])[0]?.video?.theme?.name;

            return (
                <>{theme}</>
            );
        },
        id: 'theme',
    },
    {
        Header: () => TableHeaderColumnTooltip({
            text: 'Local Title',
        }),

        Cell: ({row}: {row: any}) => {
            let title = '';

            if ((row.original.mutations || []).length === 1) {
                title = (row.original.mutations || [])[0]?.title;
            }

            return (
                <>{title}</>
            );
        },
        id: 'title',
    },
    {
        Header: () => TableHeaderColumnTooltip({text: 'Type'}),
        Cell: ({row}: {row: any}) => {
            const {captionFile, audioFile} = (row.original.mutations || [])[0] || {};

            return (
                <div style={{display: 'flex', gap: '.4rem'}}>
                    {(row.original.mutations || [])[0]?.video?.type?.name}
                    {((row.original.mutations || []).length === 1) && (
                        <>
                            {captionFile && (
                                <Tooltip text="This mutation has subtitles">
                                    <SubtitlesIcon style={{width: '1rem'}}/>
                                </Tooltip>
                            )}
                            {audioFile && (
                                <Tooltip text="This mutation has audio track">
                                    <AudioIcon style={{width: '1rem'}}/>
                                </Tooltip>
                            )}
                        </>
                    )}
                </div>
            );
        },
        id: 'type',
    },
]);

export const tableAddPlaylistVideoSubColumns = () => {
    const filledColumns = [
        {
            index: 3,
            column: {
                accessor: 'title',
            },
        }, {
            index: 4,
            column: {
                accessor: 'type',
                Cell: ({row}: {row: any}) => {
                    const {captionFile, audioFile} = row;

                    return (
                        <div style={{display: 'flex', gap: '.4rem'}}>
                            {captionFile && (
                                <Tooltip text="This mutation has subtitles">
                                    <SubtitlesIcon style={{width: '1rem'}}/>
                                </Tooltip>
                            )}
                            {audioFile && (
                                <Tooltip text="This mutation has audio track">
                                    <AudioIcon style={{width: '1rem'}}/>
                                </Tooltip>
                            )}
                        </div>
                    );
                },
            },
        },
    ];

    return generateTableSubColumns(4, filledColumns);
};
