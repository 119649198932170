import {navigate} from 'gatsby';
import {TableHeaderColumnTooltip, generateTableSubColumns} from '../../helpers/components/table';
import ButtonGroup from '../../components/core/button/buttonGroup';
import ButtonIcon from '../../components/core/button/buttonIcon';
import {
    copyVideoUrl,
    goToUrlVideo,
    goToVideoEdit,
    removeFromPlaylist,
    videoNotUploaded,
} from './videos/copy';
import {
    AudioIcon,
    CopyIcon,
    DeleteIcon,
    NavArrowRightIcon,
    SubtitlesIcon,
    YoutubeIcon,
} from '../../components/core/icons';
import {Tooltip} from '../../components/core';

export const tablePlaylistVideosColumns = (removeVideos: (uploadIds: string[]) => void) => ([
    {
        Header: () => TableHeaderColumnTooltip({text: 'MCBT/AID'}),
        hasWidthAuto: true,
        Cell: ({row}: {row: any}) => <strong>{(row.original.mutations || [])[0]?.video?.mcbt}</strong>,
        id: 'mcbt',
    },
    {
        Header: () => TableHeaderColumnTooltip({text: 'Theme'}),
        Cell: ({row}: {row: any}) => <strong>{(row.original.mutations || [])[0]?.video?.theme?.name}</strong>,
        id: 'theme',
    },
    {
        Header: () => TableHeaderColumnTooltip({text: 'Local Title'}),
        Cell: ({row}: {row: any}) => {
            let title = '';

            if ((row.original.mutations || []).length === 1) {
                title = (row.original.mutations || [])[0]?.title;
            }

            return (
                <>{title}</>
            );
        },
        id: 'title',
    },
    {
        Header: () => TableHeaderColumnTooltip({text: 'Type'}),
        Cell: ({row}: {row: any}) => {
            const {captionFile, audioFile} = (row.original.mutations || [])[0] || {};

            return (
                <div style={{display: 'flex', gap: '.4rem'}}>
                    {(row.original.mutations || [])[0]?.video?.type?.name}
                    {((row.original.mutations || []).length === 1) && (
                        <>
                            {captionFile && (
                                <Tooltip text="This mutation has subtitles">
                                    <SubtitlesIcon style={{width: '1rem'}}/>
                                </Tooltip>
                            )}
                            {audioFile && (
                                <Tooltip text="This mutation has audio track">
                                    <AudioIcon style={{width: '1rem'}}/>
                                </Tooltip>
                            )}
                        </>
                    )}
                </div>
            );
        },
        id: 'type',
    },
    {
        Header: '',
        hasWidthAuto: true,
        id: 'actions',
        Cell: ({row = {}}: {row: any}) => {
            const hasYoutubeVideoId = ![
                '',
                undefined,
                null,
            ].includes(row.original.videoId);
            const {videoUrl} = row.original;

            return (
                <ButtonGroup>
                    <ButtonIcon
                        tooltipText={hasYoutubeVideoId ? copyVideoUrl : videoNotUploaded}
                        icon={CopyIcon}
                        onClick={() => {
                            navigator.clipboard.writeText(videoUrl);
                        }}
                        disabled={!hasYoutubeVideoId}
                        sidePadding={0.9}
                    />
                    <ButtonIcon
                        tooltipText={hasYoutubeVideoId ? goToUrlVideo : videoNotUploaded}
                        icon={YoutubeIcon}
                        isButton={false}
                        href={videoUrl}
                        target="_blank"
                        disabled={!hasYoutubeVideoId}
                        sidePadding={0.9}
                    />
                </ButtonGroup>
            );
        },
    },
    {
        Header: '',
        hasWidthAuto: true,
        noSidePadding: true,
        id: 'delete',
        Cell: ({row = {}}: {row: any}) => (
            <ButtonGroup>
                <ButtonIcon
                    tooltipText={removeFromPlaylist}
                    icon={DeleteIcon}
                    onClick={() => removeVideos([(row.original.mutations || [])[0]?.upload.id])}
                    sidePadding={0.9}
                    color="#E83420"
                />
            </ButtonGroup>
        ),
    },
    {
        Header: '',
        hasWidthAuto: true,
        noRightPadding: true,
        id: 'link',
        Cell: ({row = {}}: {row: any}) => (
            <ButtonGroup>
                <ButtonIcon
                    tooltipText={goToVideoEdit}
                    icon={NavArrowRightIcon}
                    onClick={() => {
                        navigate(
                            `/videos/${(row.original.mutations || [])[0]?.video?.channel.id}/${(row.original.mutations || [])[0]?.video.id}/mutations`,
                            {
                                state: {
                                    activeMutationId: (row.original.mutations || [])[0]?.id,
                                },
                            },
                        );
                    }}
                />
            </ButtonGroup>
        ),
    },
]);

export const tablePlaylistVideosSubColumns = () => {
    const filledColumns = [
        {
            index: 3,
            column: {
                accessor: 'title',
            },
        }, {
            index: 4,
            column: {
                accessor: 'type',
                Cell: ({row}: {row: any}) => {
                    const {captionFile, audioFile} = row;

                    return (
                        <div style={{display: 'flex', gap: '.4rem'}}>
                            {captionFile && (
                                <Tooltip text="This mutation has subtitles">
                                    <SubtitlesIcon style={{width: '1rem'}}/>
                                </Tooltip>
                            )}
                            {audioFile && (
                                <Tooltip text="This mutation has audio track">
                                    <AudioIcon style={{width: '1rem'}}/>
                                </Tooltip>
                            )}
                        </div>
                    );
                },
            },
        },
    ];

    return generateTableSubColumns(8, filledColumns);
};
