import {useMutation, useQuery, useReactiveVar} from '@apollo/client';
import {navigate} from 'gatsby';
import {
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from 'react';
import {getPlaylistTabsData} from '../../../../configs/pages/playlists/tabs';
import {ActionButtons, Layout, StatusBar} from '../../../../components/layout';
import {
    Button,
    LoadingIndicator,
    Tabs,
    WarningPopup,
} from '../../../../components/core';
import {
    GetChangelogDocument,
    GetPlaylistVideosDocument,
    RemovePlaylistVideoDocument,
} from '../../../../graphql-types';
import {UserApplicationScopes} from '../../../../interfaces/applications';
import {AddIcon, DeleteIcon, NavArrowLeftIcon} from '../../../../components/core/icons';
import ButtonGroup from '../../../../components/core/button/buttonGroup';
import AddPlaylistVideoModal from '../../../../modals/playlists/addPlaylistVideo';
import Table from '../../../../components/core/table/table';
import {accountVar} from '../../../../helpers/graphql/variables';
import {tablePlaylistVideosColumns, tablePlaylistVideosSubColumns} from '../../../../configs/pages/playlistVideos';
import {PlaylistModalVariants} from '../../../../configs/pages/playlists/modals';
import * as layoutStyles from '../../../../components/layout/layout.module.css';
import toastify from '../../../../helpers/toast/toastify';

const PlaylistVideos = ({
    playlist: playlistId,
}: {playlist: string}) => {
    const account = useReactiveVar(accountVar);
    const {loading, data: playlistData, error} = useQuery(GetPlaylistVideosDocument, {variables: {playlistId}, fetchPolicy: 'network-only'});
    const [openedModal, setOpenedModal] = useState<PlaylistModalVariants | null>(null);
    const playlistTabsData = getPlaylistTabsData(true);
    const playlistTitle = useMemo(() => (playlistData ? playlistData.playlist?.name : playlistId), [playlistData, playlistId]);
    const pageTitle = useMemo(() => `Playlist | ${playlistTitle}`, [playlistTitle]);
    const [removePlaylistVideo, {loading: isSendingDelete}] = useMutation(RemovePlaylistVideoDocument);
    const [selectedRows, setSelectedRows] = useState<string[] | null>(null);
    const [deleteWarningDisplayed, setDeleteWarningDisplayed] = useState(false);
    const [onDeleteWarningConfirm, setOnDeleteWarningConfirm] = useState<() => () => void>(() => () => {});

    const handleRemoveVideos = useCallback((uploadIds: string[]) => {
        setOnDeleteWarningConfirm(() => () => {
            removePlaylistVideo({
                variables: {
                    playlistId,
                    uploadIds,
                },
                onCompleted() {
                    setSelectedRows(null);
                    setDeleteWarningDisplayed(false);
                    toastify({
                        type: 'success',
                        text: 'Video removed from playlist',
                    });
                },
                refetchQueries: [
                    {
                        query: GetChangelogDocument,
                        variables: {
                            filter: {
                                playlistIds: [playlistId],
                            },
                        },
                    },
                ],
                onQueryUpdated(observableQuery) {
                    return observableQuery.refetch();
                },
            });
        });

        setDeleteWarningDisplayed(true);
    }, [playlistId, removePlaylistVideo]);

    const tableColumns = tablePlaylistVideosColumns(handleRemoveVideos);
    const accountPermissions = useMemo(() => (account ? account.permissions : {} as UserApplicationScopes), [account]);

    const getBulkActionButtons = (
        <ButtonGroup>
            <Button
                text="Remove from playlist"
                icon={DeleteIcon}
                onClick={() => handleRemoveVideos(selectedRows || [])}
                size="tiny"
                color="white"
            />
        </ButtonGroup>
    ) as ReactNode;

    return (
        <>
            <Layout
                title={pageTitle}
                scope="videos"
            >
                {loading && (
                    <LoadingIndicator/>
                )}
                <div className={layoutStyles.pageHeader}>
                    <StatusBar
                        buttons={(
                            <>
                                <Button
                                    icon={NavArrowLeftIcon}
                                    text="Back to playlists"
                                    onClick={() => {
                                        const to = '../../';

                                        navigate(to);
                                    }}
                                    variant="outline"
                                />
                            </>
                        )}
                        title={(
                            <>
                                <span>Playlist /&nbsp;</span>
                                <strong>{playlistTitle}</strong>
                            </>
                        )}
                    />
                </div>
                <div>
                    <Tabs
                        data={playlistTabsData}
                    />
                </div>
                <ActionButtons>
                    {accountPermissions.playlists?.write && (
                        <Button
                            onClick={() => {
                                setOpenedModal(PlaylistModalVariants.addVideo);
                            }}
                            text="Add video to playlist"
                            icon={AddIcon}
                        />
                    )}
                </ActionButtons>
                <Table
                    settings={{
                        itemsName: 'videos',
                        isCompact: true,
                        isStriped: true,
                    }}
                    columns={tableColumns}
                    data={playlistData?.playlist?.uploads.map(row => {
                        const {mutations} = row;

                        if (mutations.length > 1) {
                            return {
                                ...row,
                                subRows: mutations.map(mutation => ({
                                    ...mutation,
                                })),
                            };
                        }

                        return row;
                    }) || []}
                    bulkActions={{
                        enabled: true,
                        selectedIds: selectedRows,
                        setSelectedIds: setSelectedRows,
                        buttons: getBulkActionButtons,
                        pathToId: 'id',
                        rootClass: 'closer',
                    }}
                    dataLoading={{
                        active: loading,
                        text: 'Fetching videos...',
                    }}
                    dataError={{
                        active: !!error,
                        text: error?.message || 'error',
                    }}
                    subRowStructure={tablePlaylistVideosSubColumns()}
                />
            </Layout>
            {playlistData && openedModal && openedModal === PlaylistModalVariants.addVideo && (
                <AddPlaylistVideoModal
                    setModal={setOpenedModal}
                    playlist={playlistData && {
                        id: playlistData.playlist?.id as string,
                        name: playlistData.playlist?.name as string,
                        channelId: playlistData.playlist?.channel.id as string,
                        languageId: playlistData.playlist?.language.id as string,
                    }}
                />
            )}
            {deleteWarningDisplayed && (
                <WarningPopup
                    topText={'Do you really wanna remove this video from playlist?'}
                    buttons={(
                        <>
                            <Button
                                text="Yes, remove it"
                                onClick={onDeleteWarningConfirm}
                                color={'error'}
                                disabled={isSendingDelete}
                                loading={isSendingDelete}
                            />
                            <Button
                                text="No, keep it"
                                onClick={() => {
                                    setDeleteWarningDisplayed(false);
                                }}
                            />
                        </>
                    )}
                />
            )}
        </>
    );
};

export default PlaylistVideos;
