import {ITabsData} from '../../../components/core/tabs/tabs';

// eslint-disable-next-line import/prefer-default-export
export const getPlaylistTabsData = (isSub?: boolean): ITabsData[] => [
    {
        id: 'playlistBasicInfo',
        title: 'Basic info',
        to: `${isSub ? '.' : ''}./`,
    },
    {
        id: 'playlistVideos',
        title: 'Videos',
        to: `${isSub ? '.' : ''}./videos`,
    },
    {
        id: 'playlistChangelog',
        title: 'Changelog',
        to: `${isSub ? '.' : ''}./changelog`,
    },
];
